@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
:root {
  //2025 Palette
  --lightestbrown: #EAE7E0;
  --lightbrown: #D4D9DC;
  --mocha: #938C83;
  --darkbrown: #362F25;
  --darkgreen: #47504B;
  //
  --blue: #1252a5;
  --red: #8c0f0f;
  --lightgrey: #ecebeb;
  --grey: #555555;
  --transaprent: rgba(255, 255, 255, 0);
  --accent-color: var(--darkgreen);
  --custom-header-font: Roboto Slab;
  --custom-main-font: Karla;
  --breadcrumbs-background: var(--lightestbrown);
  --link-foreground-color: var(--darkgreen);
}
