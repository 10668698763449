.blocks-group-wrapper {
  background: var(--theme-color);
  color: var(--theme-foreground-color);
  @include color-block-change-vertical-spacing();

  &:first-child {
    padding-top: 0;
  }

  & > p,
  & > span,
  & > li,
  & > ul,
  & > ol,
  & > strong,
  & > em,
  & > figcaption,
  & > a:not(.item),
  & > blockquote,
  & > h2,
  & > h3,
  & > h1 {
    color: var(--theme-foreground-color);
  }

  a:not(.item):not(
      :has(> .teaser-item),
      :has(> .listing-body),
      :has(> .card-container)
    ) {
    color: var(--link-foreground-color, --theme-foreground-color);
    text-decoration: underline;
  }

  &:first-child:has(> .documentFirstHeading) {
    padding-top: 0;
  }
}

.block {
  background-color: var(--theme-color);
}

#page-document .blocks-group-wrapper {
  .block:first-child h2,
  .block:first-child h2.headline {
    margin-top: 0;
  }
}

// If next block has different bgcolor, cancel all margin-bottom
#page-document .block.next--has--different--backgroundColor {
  margin-bottom: 0 !important;
}

// For grids
#page-document .blocks-group-wrapper .block.gridBlock {
  &.is--first--of--block-type.previous--has--same--backgroundColor {
    @include grid-block-vertical-spacing-top();
  }
  &.is--last--of--block-type.next--has--same--backgroundColor {
    margin-bottom: $grid-block-vertical-spacing-bottom;
  }
  &.next--is--same--block-type.next--has--same--backgroundColor {
    // We rely on the grid gutter to keep the vertical spacing in case grid + grid
    // So here we cancel the default block margin-bottom
    margin-bottom: 0;
  }
}

// Buttons
#page-document
  .blocks-group-wrapper
  .block.__button.next--has--different--backgroundColor {
  margin-bottom: 0;

  .button.container {
    margin-bottom: 0;
  }
}

// Color map
@mixin use-theme-colors() {
  // Page title
  h1.documentFirstHeading {
    color: var(--theme-foreground-color);
  }

  header.head-title span {
    color: var(--theme-foreground-color);
  }

  // ToC
  .table-of-contents {
    h2 {
      color: var(--theme-foreground-color);
    }
  }

  // Blocks
  .block {
    // Button
    &.__button {
      & > .button.container {
        & .ui.button {
          border-color: var(--theme-foreground-color);
          background-color: var(--theme-color);

          color: var(--theme-foreground-color);

          &:hover,
          &:active,
          &:focus {
            border-color: var(--theme-foreground-color);
            background-color: var(--theme-foreground-color);
            color: var(--theme-color);
          }
        }
      }
    }

    // Teaser
    &.teaser {
      .teaser-item {
        border-bottom-color: var(--theme-foreground-color) !important;
      }

      h2,
      p,
      .headline {
        color: var(--theme-foreground-color);
      }
    }

    // Separator
    &.separator {
      .line:not(.inner)::after {
        border-color: var(--theme-foreground-color);
      }
    }

    // Introduction
    &.introduction {
      a,
      strong,
      li,
      ul,
      ol,
      p,
      h2,
      h3 {
        color: var(--theme-foreground-color);
      }
    }

    // Listing
    &.listing,
    &.search {
      h2.headline {
        color: var(--theme-foreground-color);
      }

      .emptyListing {
        color: var(--theme-foreground-color);
      }

      .listing-item {
        border-bottom-color: var(--theme-foreground-color) !important;

        a > .listing-body {
          h3,
          h2,
          a,
          p,
          span {
            color: var(--theme-foreground-color);
          }
        }

        .head-title span,
        .head-title {
          color: var(--theme-foreground-color);
        }

        .card-container {
          background-color: var(--theme-high-contrast-color);

          .content,
          .content p {
            color: var(--theme-foreground-color);
          }
        }
      }

      &.imageGallery {
        div > .ui.container {
          background: none;
        }
        .image-wrapper {
          .cookie-consent-overlay {
            .cookie-consent-buttons {
              background-color: black;

              a {
                color: var(--theme-foreground-color);
              }
            }
          }
        }

        figcaption {
          .credits {
            p b {
              color: var(--theme-foreground-color);
            }
            a {
              text-decoration: underline;
            }
          }
        }

        .image-gallery-thumbnails-wrapper .image-gallery-thumbnails-container {
          .image-gallery-thumbnail {
            &:hover,
            &.active {
              border: 3px solid var(--theme-foreground-color);
            }
          }
        }

        .image-gallery-description {
          color: var(--theme-low-contrast-foreground-color);

          .download {
            a {
              color: var(--theme-low-contrast-foreground-color);
              text-decoration: underline;
            }
          }
        }
      }

      .image-gallery-content {
        background: none !important;
        figcaption {
          .title,
          .description,
          .description > div,
          .credits,
          .credits a {
            color: var(--theme-foreground-color);
          }
        }
      }

      .image-gallery .image-gallery-content .image-gallery-slide-wrapper {
        button.primary.image-gallery-icon.image-gallery-fullscreen-button,
        button.button.image-gallery-icon.image-gallery-play-button {
          border-color: var(--theme-foreground-color);
          svg path {
            fill: var(--theme-foreground-color) !important;
          }

          &:hover,
          &:active,
          &:focus {
            svg {
              background: var(--theme-foreground-color) !important;

              & path {
                fill: var(--theme-color) !important;
              }
            }
          }
        }

        button.ui.button.image-gallery-icon {
          path {
            stroke: var(--theme-foreground-color);
          }
        }
      }
      .pagination-wrapper {
        .ui.pagination.desktop-pagination.menu {
          display: flex;
          background: none;

          a {
            max-height: 38px !important;
            border-color: var(--theme-foreground-color);
            color: var(--theme-foreground-color);

            &:nth-child(n):not(:last-child) {
              border-right: none;
            }

            &.disabled {
              color: var(--theme-low-contrast-foreground-color) !important;
            }

            &.active {
              background: var(--theme-foreground-color);
              color: var(--theme-color);
            }
          }
        }

        .total {
          color: var(--theme-foreground-color);
        }
      }

      .pagination-wrapper {
        .ui.pagination.menu {
          display: flex;
          background: none;

          a {
            max-height: 38px !important;
            border-color: var(--theme-foreground-color);
            color: var(--theme-foreground-color);

            &.disabled {
              color: var(--theme-low-contrast-foreground-color) !important;
            }

            &.active {
              background: var(--theme-foreground-color);
              color: var(--theme-color);
            }
          }
        }

        .total {
          color: var(--theme-foreground-color);
        }
      }
    }

    // Slider
    &.slider {
      .slider-dots {
        .slider-dot {
          &::after {
            background-color: var(--theme-high-contrast-color);
          }
        }
        .slider-dot--selected {
          &::after {
            background: var(--theme-foreground-color);
          }
        }
      }
    }

    // Quote
    &.quote {
      .line {
        border-color: var(--theme-foreground-color);
      }

      footer {
        color: var(--theme-foreground-color);
      }
    }

    // Images & Audio
    &.image,
    &.audio,
    &.video,
    &.imageslider {
      figcaption {
        .title,
        .description,
        .description > div,
        .credits,
        .credits a {
          color: var(--theme-foreground-color);
        }

        .credits div > a,
        .credits > a {
          text-decoration: underline !important;
        }
      }
    }

    // Search
    &.search {
      .search-input {
        border: none !important;
        background: var(--theme-high-contrast-color) !important;

        .ui.input {
          background-color: transparent !important;

          input {
            color: var(--theme-foreground-color);

            &::placeholder {
              color: var(--theme-low-contrast-foreground-color) !important;
            }
          }
        }

        button.search-input-clear-icon-button,
        button.search-input-live-icon-button {
          background-color: transparent !important;

          svg path {
            fill: var(--theme-foreground-color) !important;
          }
        }
      }

      .search-sort-wrapper {
        border: 1px solid var(--theme-foreground-color);

        .react-select__control {
          background-color: transparent;

          .react-select__single-value {
            color: var(--theme-foreground-color);
          }

          .react-select__indicator svg {
            &,
            & path {
              fill: var(--theme-foreground-color);
            }
          }
        }

        .ui.basic.compact.icon.button.active {
          background: var(--theme-foreground-color);
          svg > path {
            fill: var(--theme-color);
            &:first-child {
              stroke: var(--theme-color);
            }
          }
        }

        .ui.basic.compact.icon.button {
          border: none !important;
          margin: 0 !important;
          background-color: var(--theme-color);

          &.active {
            background: var(--theme-foreground-color) !important;

            svg > path {
              fill: var(--theme-color);
              &:first-child {
                stroke: var(--theme-color);
              }
            }
          }
          svg > path {
            fill: var(--theme-foreground-color);
            &:first-child {
              stroke: var(--theme-foreground-color);
            }
          }

          &:not(.active):hover {
            background: var(--theme-color) !important;
          }
        }
      }

      .facets {
        h4.header,
        label {
          color: var(--theme-low-contrast-foreground-color);
        }

        .react-select-container {
          .react-select__menu {
            background-color: var(--theme-high-contrast-color);

            .react-select__option {
              background-color: var(--theme-high-contrast-color);
              color: var(--theme-foreground-color) !important;

              &:hover {
                background: var(--theme-color);
              }
            }
          }
        }

        .react-select__control {
          border-bottom: 1px solid var(--theme-foreground-color);
          background-color: transparent;

          .react-select__indicator svg path {
            fill: var(--theme-foreground-color) !important;
          }

          .react-select__multi-value {
            background-color: var(--theme-high-contrast-color) !important;

            &,
            .react-select__multi-value__label {
              color: var(--theme-foreground-color);
            }
          }

          .react-select__single-value {
            color: var(--theme-foreground-color);
          }

          .date-input {
            color: black !important;
          }
          .CalendarMonth_caption > strong {
            color: black !important;
          }
        }

        .react-select__placeholder {
          color: var(--theme-foreground-color);
        }

        .DateRangePickerInput_arrow_svg {
          fill: var(--theme-foreground-color);
        }
      }

      .CalendarMonth_caption strong,
      .CalendarDay__default:hover,
      .DayPicker_weekHeader_li small {
        color: #252525;
      }

      .DateRangePickerInput,
      .DateInput input,
      .DateInput {
        border-color: var(--theme-foreground-color);
        background: none !important;
        color: var(--theme-foreground-color);

        &::placeholder {
          color: var(--theme-low-contrast-foreground-color) !important;
        }
      }

      .DateRangePickerInput_clearDates svg {
        color: var(--theme-foreground-color);
      }

      .ui.toggle.checkbox label::before {
        border: 1px solid var(--theme-foreground-color);
      }
      .search-sort-on {
        border: none !important;
        .sorted-label {
          color: var(--theme-foreground-color);

          .sorted-label-value {
            color: var(--theme-foreground-color);
          }
        }
        .react-select__menu {
          border-color: var(--theme-foreground-color) !important;

          .react-select__menu-list {
            background: var(--theme-color);

            .react-select__option {
              background-color: var(--theme-high-contrast-color);
              color: var(--theme-foreground-color) !important;

              svg {
                fill: var(--theme-foreground-color) !important;
              }

              &:hover {
                background: var(--theme-color);
              }
            }
          }
        }
      }

      .search-details {
        h2,
        span {
          color: var(--theme-foreground-color);
        }
      }
    }

    // Heading
    &.heading {
      h2.heading {
        color: var(--theme-foreground-color);
      }
    }

    // Image slider
    &.imageslider {
      .image-gallery-thumbnails-wrapper .image-gallery-thumbnails-container {
        .image-gallery-thumbnail {
          &:hover,
          &.active {
            border-color: var(--theme-foreground-color) !important;
          }
        }
      }
      .image-gallery .image-gallery-content .image-gallery-slide-wrapper {
        button.primary.image-gallery-icon.image-gallery-fullscreen-button,
        button.button.image-gallery-icon.image-gallery-play-button {
          svg path {
            fill: var(--theme-foreground-color) !important;
          }

          &:hover,
          &:active,
          &:focus {
            svg {
              background: var(--theme-foreground-color) !important;

              & path {
                fill: var(--theme-color) !important;
              }
            }
          }
        }
      }

      figcaption {
        padding-right: 2em;
        padding-left: 2em;
      }
    }

    // Grid
    &.gridBlock {
      .headline {
        color: var(--theme-foreground-color);
      }
      .grid-block-slate .slate,
      .slate-editor {
        background: var(--theme-high-contrast-color);
        color: var(--theme-foreground-color);

        & > * {
          color: var(--theme-foreground-color);
        }
      }

      // Use values assigned to the parent Grid block
      .block {
        --theme-high-contrast-color: inherit !important;
        --theme-color: inherit !important;
        --theme-high-contrast-color: inherit !important;
        --theme-foreground-color: inherit !important;
        --theme-low-contrast-foreground-color: inherit !important;
      }

      .block.teaser {
        padding-top: 0;
        background: var(--theme-high-contrast-color);

        .teaser-item {
          background: var(--theme-high-contrast-color);
        }
        .grid-teaser-item {
          background: var(--theme-high-contrast-color);

          .download-file {
            color: var(--theme-foreground-color);
          }
          &.hideImage {
            justify-content: start;
          }
        }
      }

      .block.image {
        figure figcaption {
          .credits a {
            text-decoration: underline !important;
          }
        }
      }

      .column > .slate,
      .grid-items .slate:not(.inner) {
        background-color: var(--theme-high-contrast-color);
        & > * {
          color: var(--theme-foreground-color);
        }
      }

      h2.headline {
        color: var(--theme-foreground-color);
      }
      .grid-teaser-item {
        &.hideImage {
          background: var(--theme-high-contrast-color);
        }

        h2,
        a,
        p,
        strong,
        .download-file {
          color: var(--theme-foreground-color);
        }
      }

      .grid-block-image .block.image {
        figcaption {
          .description,
          .credits {
            padding: 0 !important;
            a {
              text-decoration: underline;
            }
          }
        }
      }

      .grid-block-slate {
        .slate {
          strong,
          em {
            color: var(--theme-foreground-color);
          }
        }
      }
    }

    .cookie-consent-buttons {
      button.button {
        color: black;
      }
      a,
      p {
        color: $white !important;
      }
    }
  }
  // Accordion
  .accordion-block,
  .block-editor-accordion {
    .ui.styled.accordion {
      border-color: var(--theme-foreground-color);

      .title.accordion-title {
        background: var(--theme-color);

        & > span {
          color: var(--theme-low-contrast-foreground-color);
        }

        &.active > span {
          color: var(--theme-foreground-color);
        }

        & > svg {
          fill: var(--theme-low-contrast-foreground-color) !important;
        }

        &.active > svg {
          fill: var(--theme-foreground-color) !important;
        }
      }

      .rah-static {
        background: var(--theme-color) !important;
      }

      &:last-child {
        border-color: var(--theme-foreground-color);
      }

      figcaption .title,
      figcaption .description {
        color: var(--theme-foreground-color);
      }
    }
    // Inherit colors from parent Accordion
    .blocks-group-wrapper,
    .block {
      --theme-high-contrast-color: inherit !important;
      --theme-color: inherit !important;
      --theme-high-contrast-color: inherit !important;
      --theme-foreground-color: inherit !important;
      --theme-low-contrast-foreground-color: inherit !important;
    }
  }

  table.ui.table.slate-table-block {
    border-color: var(--theme-low-contrast-foreground-color);

    tr td,
    th {
      border-color: var(--theme-low-contrast-foreground-color);
    }

    th {
      background-color: var(--theme-high-contrast-color) !important;

      p {
        color: var(--theme-low-contrast-foreground-color) !important;
      }
    }

    td {
      color: var(--theme-foreground-color);
    }

    tr {
      background: var(--theme-color);
    }

    &.striped tbody tr:nth-child(2n) {
      background-color: var(--theme-high-contrast-color) !important;
    }
  }

  //Edit mode
  #page-edit,
  #page-add {
    .block {
      & [data-slate-placeholder='true'] {
        color: var(--theme-low-contrast-foreground-color) !important;
      }
    }

    .block-editor-slateTable {
      .slate-table-block-wrapper {
        thead.hide-header-edit {
          opacity: 1;

          th span {
            color: darkGrey !important;
          }
        }
      }
    }

    [class^='block-editor-'] {
      a.slate-editor-link {
        color: var(--theme-foreground-color);
      }

      .block {
        .text-slate-editor-inner {
          span {
            color: var(--theme-foreground-color) !important;
          }
        }

        .heading {
          h2.editable {
            color: var(--theme-foreground-color);
          }
        }

        .teaser {
          h2,
          p,
          .headline {
            color: var(--theme-foreground-color);
          }
        }

        .title {
          [data-slate-placeholder='true'] {
            top: unset !important;
            bottom: 0;
          }
        }

        .listing,
        .search {
          h2,
          p,
          h3,
          .react-select__placeholder {
            color: var(--theme-foreground-color) !important;
          }
        }

        .search {
          .search-input-live-icon-button {
            background: none !important;
          }

          .search-sort-wrapper {
            .ui.basic.compact.icon.button:not(.active) {
              background: $white !important;
            }
          }
        }
        .gridBlock {
          .slate-editor {
            a,
            ul,
            ol,
            li {
              color: var(--theme-foreground-color);
            }
          }

          .renderer-wrapper.empty .uber-grid-default-item {
            p {
              color: black !important;
            }
          }
        }
      }
    }
  }

  // Header
  header.header-wrapper {
    background: var(--header-background);
    .header {
      .tools-wrapper {
        .tools {
          a,
          .anontools a,
          .anontools::after {
            color: var(--header-foreground);
          }
        }
      }

      // Navigation
      nav.navigation {
        button.item {
          color: var(--header-foreground);
        }
        .submenu-wrapper {
          .submenu.active {
            background: var(--fatmenu-background);

            .submenu-inner {
              a,
              h2 {
                color: var(--fatmenu-foreground);
              }

              a span {
                &:hover {
                  border-color: var(--fatmenu-foreground);
                }
              }

              .subitem-wrapper {
                border-color: var(--fatmenu-foreground);
              }

              button.close {
                background: none;

                &:hover,
                &:focus {
                  outline: 1px solid var(--fatmenu-foreground);
                }

                svg path {
                  fill: var(--fatmenu-foreground);
                }
              }
            }
          }
        }
      }

      // Search
      .search-bar {
        background-color: var(--search-background);

        .searchbox {
          border-bottom-color: var(--search-foreground) !important;
          input {
            background-color: var(--search-background);
            color: var(--search-foreground);
            &::placeholder {
              color: var(--search-foreground);
            }
          }

          & > button:not(:hover, :active, :focus) {
            svg path {
              fill: var(--secondary-foreground-color);
            }
          }
        }
      }

      // Hamburger Menu
      .hamburger-wrapper {
        .hamburger,
        .hamburger-box,
        .hamburger-inner {
          &::before,
          &::after {
            background: var(--primary-foreground-color);
          }

          .hamburger-box {
            &::after {
              background: var(--primary-foreground-color);
            }
          }
        }
      }

      // Mobile Nav
      .mobile-nav {
        .search {
          .search-button {
            button:not(:hover, :active, :focus) {
              svg path {
                fill: var(--fatmenu-foreground);
              }
            }
          }
        }

        .menu-drawer {
          background: var(--fatmenu-background);
          ul {
            li {
              border-color: var(--fatmenu-foreground);
              color: var(--fatmenu-foreground);

              a {
                color: var(--fatmenu-foreground);
              }
            }
          }

          .search-header {
            button {
              color: var(--fatmenu-foreground);
            }
          }

          .searchbox > button:not(:hover, :active, :focus) {
            svg path {
              fill: var(--fatmenu-foreground);
            }
          }
        }
      }

      .search {
        .search-button {
          button:not(:hover, :active, :focus) {
            svg path {
              fill: var(--header-foreground);
            }
          }
        }
      }
    }

    .logo {
      a {
        img {
          fill: red !important;

          * {
            fill: red !important;
          }
        }
      }
    }
  }

  // Breadcrumbs
  #main > .breadcrumbs {
    background: var(--breadcrumbs-background);

    .section.active,
    .divider::before {
      color: var(--breadcrumbs-foreground) !important;
    }

    a.section {
      color: var(--link-foreground-color, --breadcrumbs-foreground);
    }

    a.home {
      svg {
        fill: var(--link-foreground-color, --breadcrumbs-foreground) !important;
      }
    }
  }

  // Footer
  #footer .footer {
    background: var(--footer-background);

    .footer-message {
      color: var(--footer-foreground);

      a {
        color: var(--link-foreground-color, --footer-foreground) !important;
      }
    }

    a.item,
    .footer-branding {
      color: var(
        --link-foreground-color,
        --secondary-foreground-color
      ) !important;
    }
    li.item {
      border-right-color: var(
        --link-foreground-color,
        --secondary-foreground-color
      ) !important;
      color: var(
        --link-foreground-color,
        --secondary-foreground-color
      ) !important;
    }
  }

  // Search results page
  &.section-search {
    .ui.basic.segment.content-area {
      margin-bottom: 0;
      main {
        padding-bottom: 1rem;
        background: var(--primary-color);

        h1.documentFirstHeading,
        & > span,
        & > div {
          color: var(--primary-foreground-color);
        }

        #content-core {
          article {
            a {
              color: var(--link-foreground-color, --primary-foreground-color);
            }
          }
        }
      }

      .items_total {
        color: var(--primary-foreground-color);

        .header {
          .header-content {
            .sort-by,
            button {
              z-index: 1;
              color: var(--primary-foreground-color);

              &::before {
                border-color: var(--primary-foreground-color);
              }
            }
          }
        }
      }
    }
  }
}

body {
  @include use-theme-colors();
}
