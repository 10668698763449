// Intentionally empty
// Used for customizations in other add-ons
@import 'variables';
//General
html {
  scroll-behavior: smooth;
}
a {
  color: var(--darkgreen);
}
.content-area #page-edit .ui.container a.external::after, .content-area #page-edit .ui.container a.external::after, .content-area .q.container a.external::after, .content-area .q.container a.external::after, .content-area .a.container a.external::after, .content-area .a.container a.external::after {
  display: none;
}
abbr[title] {
  text-decoration: none;
}
//Navigation
.logo img {
  max-width: 200px;
}
.header-wrapper .header {
  margin: 15px auto;
}
.header-wrapper .header .logo-nav-wrapper {
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
}
.header-contact-btn a .ui.button {
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  padding: 20px;
  background: transparent;
  color: var(--darkgreen);
  border: 1px solid var(--darkgreen);
  border-radius: 0;
  transition: color 0.3s ease;
}
/* Background pseudo-element */
.header-contact-btn a .ui.button::before {
  content: "";
  position: absolute;
  top: 0;
  right: -100%; /* Start offscreen to the right */
  width: 100%;
  height: 100%;
  background-color: var(--darkgreen);
  z-index: 1; /* Below text, but above background */
  transition: right 0.4s ease;
}
/* Wrap text in a span to layer it correctly */
.header-contact-btn a .ui.button span {
  position: relative;
  z-index: 2; /* Above pseudo-element */
}
/* Hover state */
.header-contact-btn a .ui.button:hover {
  color: white;
}
.header-contact-btn a .ui.button:hover::before {
  right: 0; /* Slide in background from right */
}
.section-contact .header-contact-btn {
  display: none;
}
.section-contact .header-wrapper .header .logo-nav-wrapper .navigation {
  align-self: flex-end;
  justify-content: flex-end;
}
.mobile-nav {
  z-index: 1000;
}
.navigation .ui.secondary.pointing.menu a {
  font-family: var(--custom-main-font, sans-serif) !important;
  font-weight: 300 !important;
  text-transform: initial !important;
}
.ui.secondary.pointing.menu .active.item,
.ui.secondary.pointing.menu .active.item:hover,
.ui.secondary.pointing.menu .item:hover {
  border-color: var(--darkgreen);
}
//Slider
.block.slider .teaser-item .teaser-item-title {
  background: rgba(54,47,37,0.85);
}
.block.slider .teaser-item .teaser-item-title .title h2 {
  font-size: 40px;
}
.block.slider .teaser-item .teaser-item-title button {
  border: 1px solid #fff;
  color: #fff;
}
.block.slider .teaser-item .teaser-item-title button:hover {
  background: #fff;
  color: var(--darkgreen);
}
.blocks-group-wrapper:has(.block.slider) {
  padding-bottom: 0;
}
.block.slider {
  margin-bottom: 0 !important;
}
//Slide Carousel
.slick-slide p {
  color: #000;
  font-size: 16px;
  line-height: initial;
}
.block.carousel h2.headline {
  padding-top: 2em;
  padding-bottom: 0.5em;
  margin-right: 8px;
  margin-bottom: 0;
  margin-left: 8px;
  font-weight: 600;
}
.block.carousel .grid-image-wrapper img {
  position: initial;
  aspect-ratio: initial;
}
.block.carousel .grid-image-wrapper {
  padding-top: 0;
  margin: 0 20px;
}
.view-viewview .block.carousel h3, .view-viewview .block.carousel p {
  display: none;
}
.slick-dots {
  bottom: -50px;
}
.slick-track {
  display: flex;
  align-items: center;
}
//Home
.siteroot #why-choose-juizi, .siteroot #trusted-by, .siteroot .columns-view {
  text-align: center;
}
//Footer
#footer {
  margin-top: 0;
  background: var(--darkbrown);
}
#footer-main {
  font-size: 16px;
}
#footer-main h4 {
  margin-bottom: 8px;
}
#footer-main a {
  color: #fff;
  font-size: 16px;
}
#footer-main .item {
  margin-bottom: 5px;
}
.below-footer {
  margin: 10px 0;
  color: #ccc;
  font-size: 14px;
}
.below-footer a {
  color: #ccc;
  font-size: 14px;
}
//Breadcrumbs
body:not(.section-news) #main > .breadcrumbs {
  display: none;
}
#main .breadcrumbs {
  padding: 10px 0;
}
body #main > .breadcrumbs a.section,
#main .breadcrumbs .breadcrumb .divider::before {
  color: #000 !important;
}
body #main > .breadcrumbs a.home svg {
  fill: #000 !important;
}
#main .breadcrumbs .breadcrumb .section.active {
  display: none !important;
}
//Search block
#page-add .block.search .search-input-resultscount-sort,
#page-document .block.search .search-input-resultscount-sort,
#page-edit .block.search .search-input-resultscount-sort {
  background: var(--darkgreen);
}
body .block.search .search-details h2,
body .block.search .search-details span {
  color: #fff;
}
#page-document .block.search .search-details-sort,
#page-add .block.search .search-details-sort,
#page-edit .block.search .search-details-sort {
  background-color: var(--darkgreen);
}
body .block.search .search-input {
  border-radius: 12px;
}
#page-document .block.search .search-input-resultscount-sort,
#page-add .block.search .search-input-resultscount-sort,
#page-edit .block.search .search-input-resultscount-sort {
  margin-bottom: 40px;
  padding: 10px;
}
.block.listing .pagination-wrapper .ui.pagination.menu,
.column > .block.listing .pagination-wrapper .ui.pagination.menu,
.block.search .pagination-wrapper .ui.pagination.menu,
.block-editor-search .pagination-wrapper .ui.pagination.menu {
  margin-bottom: 0;
}
body .block.listing .pagination-wrapper .total,
body .block.search .pagination-wrapper .total {
  margin-top: 10px;
  color: #ccc;
  font-size: 16px;
}
#page-document .block.search .search-input .ui.input input, #page-add .block.search .search-input .ui.input input, #page-edit .block.search .search-input .ui.input input {
  background-color: #fff;
}
.block.search .effectiveDate {
  margin-bottom: 15px;
}
.block.search .ui.one.column.grid.advanced-item .column {
  padding: 0 !important;
}
.block.search .ui.one.column.grid.advanced-item .column.advancedImage {
  padding-left: 15px !important;
}
.block.search .ui.one.column.grid.advanced-item .column.advancedImage img {
  aspect-ratio: unset !important;
}
.block.search .ui.grid > .column:not(.row) {
  padding-bottom: 40px;
}
//Teaser blocks
.teaser-item .image-wrapper img {
  aspect-ratio: initial !important;
}
//General
#page-document .blocks-group-wrapper > h2,
#page-document .blocks-group-wrapper > h3,
#page-document .blocks-group-wrapper > h4,
#page-document .blocks-group-wrapper > h5,
#page-document .blocks-group-wrapper > h6,
#page-document .blocks-group-wrapper > ul,
#page-document .blocks-group-wrapper > ol,
#page-document .blocks-group-wrapper > li,
#page-document .blocks-group-wrapper > p,
#page-document .blocks-group-wrapper > span,
#page-document .blocks-group-wrapper > blockquote,
#page-document .blocks-group-wrapper > .block.image.align.left.medium,
#page-document .blocks-group-wrapper > .block.image.align.left.small,
#page-document .blocks-group-wrapper > .block.image.align.right.medium,
#page-document .blocks-group-wrapper > .block.video.align.left,
#page-document .blocks-group-wrapper > .block.video.align.right,
#page-document .blocks-group-wrapper > .block.maps.align.left,
#page-document .blocks-group-wrapper > .block.maps.align.right,
#page-document .blocks-group-wrapper > .block.image.align.right.small,
#page-document .blocks-group-wrapper > .block.video.align.center .video-inner,
#page-document .blocks-group-wrapper > .block.maps.align.center .maps-inner,
#page-document .blocks-group-wrapper .download-event,
#page-document .blocks-group-wrapper .block.separator.has--align--left .line,
#page-document .blocks-group-wrapper .block.separator.has--align--center .line,
#page-document .blocks-group-wrapper > pre,
#page-document .blocks-group-wrapper > .block.code {
  max-width: var(--default-container-width);
}
#page-document .blocks-group-wrapper.default a {
  --theme-foreground-color: var(--darkgreen) !important;
  --theme-low-contrast-foreground-color: var(--darkgreen) !important;
}
.siteroot .documentFirstHeading {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h1.documentFirstHeading {
  font-family: var(--custom-header-font, sans-serif) !important;
  font-weight: 300;
  hyphens: initial;
}
p {
  hyphens: initial;
}
body.view-viewview:not(.contenttype-news-item) h1.documentFirstHeading {
  margin-bottom: 40px;
  display: none;
}
.blocks-group-wrapper:first-of-type .heading-wrapper h2 {
  margin-top: 40px;
}
body.view-editview:not(.contenttype-news-item) .block-editor-title::after {
  content: "The title will not show up. Please add a Header block to use as a page title";
  display: block;
  text-align: center;
  color: red;
}
.blocks-group-wrapper h2:first-child {
  padding-top: 40px;
}
.blocks-group-wrapper:first-of-type h2:first-child {
  margin-top: 0;
  padding-top: 0;
}
.blocks-group-wrapper h2 {
  margin-bottom: 20px;
}
.blocks-group-wrapper h2.heading:last-of-type {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 0;
}
h3 {
  margin-top: 40px;
}
.blocks-group-wrapper h1.documentFirstHeading {
  margin-bottom: 20px;
}
.documentDescription {
  max-width: var(--default-container-width) !important;
}
.contenttype-news-item #page-document .dates {
  margin-bottom: 0;
}
.contenttype-news-item h1.documentFirstHeading {
  padding-top: 10px;
}
.block.image.align.right img {
  width: 50%;
  height: auto;
  margin: 0 0 20px 20px;
  float: right;
}
.block.__button .button.container {
  padding-top: 50px;
  padding-bottom: 50px;
}
#page-add .block-editor-separator,
#page-document .block.separator,
#page-edit .block-editor-separator {
  margin-top: 20px;
  margin-bottom: 40px;
}
.blocks-group-wrapper > *:last-child::after {
  content: '';
  display: block;
  clear: both;
}
.block.image.has--block-width--default figure:not(.left, .right), .block.image.has--block-width--narrow figure:not(.left, .right), .block.image.has--block-width--full figure:not(.left, .right) {
  margin: 0 !important;
}
.blocks-group-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}
p, ul, ol {
  margin-bottom: 15px;
}
.block.image.align.left, .block.image.align.right {
  margin-top: 30px;
}
.content-area img {
  border-radius: 7px;
}
//Columns
#page-document .blocks-group-wrapper .columns-view {
  max-width: var(--default-container-width);
}
.column-blocks-wrapper.price-left {
  border-radius: 15px 0 0 15px;
  box-shadow: 0px 2px 8px -2px #000;
  padding: 0 !important;
  overflow: hidden;
}
.column-blocks-wrapper.price-middle {
  position: relative;
  z-index: 5;
  border-radius: 15px;
  box-shadow: 0px 2px 8px -2px #000;
  padding: 0 !important;
  overflow: hidden;
}
.column-blocks-wrapper.price-right {
  border-radius: 0 15px 15px 0;
  box-shadow: 0px 2px 8px -2px #000;
  padding: 0 !important;
  overflow: hidden;
}
#page-document .columns-view .blocks-group-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.column-blocks-wrapper .blocks-group-wrapper {
  background: transparent !important;
}
.price-left h2, .price-middle h2, .price-right h2, 
.price-left p:last-of-type, .price-middle p:last-of-type, .price-right p:last-of-type {
  text-align: center;
  background: var(--mocha);
  color: #fff;
  padding: 15px;
  font-family: var(--custom-header-font);
}
.price-middle h2, .price-middle p:last-of-type {
  background: var(--red);
}
.price-left p:last-of-type a, .price-middle p:last-of-type a, .price-right p:last-of-type a {
  font-size: 22px;
  font-weight: 300;
  display: block;
  color: #fff !important;
  font-family: var(--custom-header-font);
  font-size: 30px
}
.section-pricing p sup {
  display: block;
  text-align: center;
  margin-top: 40px;
  line-height: 20px;
}
.column-blocks-wrapper.rounded {
  border-radius: 15px;
  box-shadow: 0px 2px 8px -2px #000;
}
//Button
body .block.__button > .button.container .ui.button {
  border-color: var(--theme-high-contrast-color);
  color: var(--theme-high-contrast-color);
}
body .block.__button > .button.container .ui.button:hover, body .block.__button > .button.container .ui.button:active, body .block.__button > .button.container .ui.button:focus {
  border-color: var(--theme-high-contrast-color);
  background-color: var(--theme-high-contrast-color);
}
.blocks-group-wrapper .block.__button .button.container {
  padding-top: 30px;
}
.blocks-group-wrapper .block.__button .button.container:only-of-type {
  padding-bottom: 30px;
}
.blocks-group-wrapper div:only-of-type .button.container button {
  text-transform: uppercase;
  font-size: 120%;
  padding: 20px;
}
//Testimonials
.column-blocks-wrapper.testimonial .blocks-group-wrapper {
  padding: 0 20px 0 20px !important;
  background: var(--lightestbrown) !important;
  border-radius: 7px;
  color: var(--darkbrown);
  border-left: 5px solid var(--darkgreen);
  height: 100%;
}
.column-blocks-wrapper.testimonial.three {
  padding-left: 15px;
  padding-right: 15px;
}
.column-blocks-wrapper.testimonial.three .blocks-group-wrapper {
  border-left: 0;
  border-bottom: 5px solid var(--darkgreen);
  text-align: center;
}
.column-blocks-wrapper.testimonial p:nth-child(1) {
  padding-top: 15px;
  font-style: italic;
}
.column-blocks-wrapper.testimonial p:nth-child(2) {
  font-weight: 600;
  margin-bottom: 0;
}
.column-blocks-wrapper.testimonial p:nth-child(3) {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 15px;
}
//Why Juizi 
.siteroot .columns-view > .column {
  margin-top: 0;
  margin-bottom: 0;
}
.column-blocks-wrapper.home-why-juizi .blocks-group-wrapper {
  padding: 20px !important;
  background: #eee !important;
  border-radius: 7px;
  height: 100%;
}
.column-blocks-wrapper.home-why-juizi .blocks-group-wrapper h3 {
  font-size: 26px;
}
.column-blocks-wrapper.home-why-juizi .blocks-group-wrapper p {
  font-size: 14px;
  line-height: 20px;
}
.column-blocks-wrapper.home-why-juizi .blocks-group-wrapper p:last-child {
  margin-bottom: 20px;
}
//Portfolio
.section-portfolio .ui.card {
  box-shadow: 0 2px 7px -4px #000;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-portfolio .ui.card a.image {
  background: transparent;
  padding: 20px;
}
.section-portfolio .ui.card a.image div.gradient {
  display: none;
}
//Contact page
.section-contact h2.heading {
  text-align: center;
}
.section-contact #page-document .blocks-group-wrapper .columns-view {
  max-width: var(--layout-container-width) !important;
  padding: 0 50px;
}
.section-contact .columns-view .nine.wide {
  border: 1px solid var(--mocha);
  border-radius: 8px;
  padding: 0;
}
.section-contact .columns-view .three.wide {
  padding: 0 40px 0 0 !important;
}
.section-contact .columns-view .three.wide .block.image:not(:first-of-type) {
  margin-top: 50px;
}
.section-contact #navigation .item:last-child {
  margin-right: 0;
}
//Mailerlite
#mlb2-24694814.ml-form-embedContainer .ml-form-embedWrapper.embedForm {
  max-width: var(--default-container-width);
  width: 100%;
}
@media only screen and (max-width: 940px) {
  .header {
    padding: 0 15px;
  }
  .column-blocks-wrapper.testimonial {
    margin-left: 30px;
    margin-right: 30px;
    padding: 15px 0 !important
  }
  .price-left, .price-middle, .price-right {
    margin: 20px 30px;
    border-radius: 15px !important;
  }
  .price-left .blocks-group-wrapper > *, .price-middle .blocks-group-wrapper > *, .price-right .blocks-group-wrapper > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .search.block .column.right .ui.grid {
    flex-direction: column;
  }
  .search.block .column.right .ui.grid > div {
    width: 100% !important;
  }
  .block.search .ui.one.column.grid.advanced-item .column.advancedImage {
    padding-left: 0 !important;
    padding-top: 20px !important;
  }
  .header-contact-btn {
    flex-basis: 100%;
    margin-top: 15px;
  }
  .header-contact-btn a .ui.button {
    width: 100%;
    padding: 15px 20px;
  }
  .section-contact .columns-view .three.wide {
    margin-top: 50px;
    padding: 0 !important;
  }
  .section-contact .columns-view .nine.wide {
    margin: 0 15px;
  }
}
